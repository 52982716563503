////
/// @group wk-css.components.header
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.3.0
/// @access public
////

@import 'header.variables';

@include wk-exports('header') {

    %scss-deps-header {
        components: wk-component-dependencies(toolbelt);
        utils: wk-utils-dependencies(
            function wk-primary-color,
            function wk-rem,
            mixin breakpoint,
            mixin container,
            mixin wk-hidden,
            mixin wk-shown,
            mixin wk-target-icons,
            mixin wk-unstyle-list,
            variable wk-body-link-hover-color,
            variable wk-desktop-max-width,
            variable wk-font-stacks,
            variable wk-page-content-padding,
            variable wk-target-tablet-desktop,
            variable wk-menu-opacity,
            variable wk-z-index
        );
    }

    .wk-fixed-top {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $wk-fixed-top-z-index;
    }

    .wk-header {
        background-color: $wk-header-background-color;
        width: 100%;

        > .wk-header-container {
            @include container;

            position: relative;

            @include breakpoint($wk-target-tablet-desktop) {
                padding: wk-rem(9px) $wk-page-content-padding;
            }

            @include breakpoint($wk-desktop-max-width + (2 * $wk-page-content-padding)) {
                padding-left: 0;
                padding-right: 0;
                padding-top: wk-rem(9px);
            }
        }

        .wk-logo {
            display: block;
            float: $wk-header-wk-logo-float;
            margin: $wk-header-wk-logo-margin-small;

            @include breakpoint($wk-target-tablet-desktop) {
                margin: $wk-header-wk-logo-margin-large;
            }
        }

        .wk-nav,
        .wk-more-menu {
            > li {
                display: block;

                @include breakpoint($wk-target-tablet-desktop) {
                    padding: 0;
                }

                //new icons
                [wk-icon] {
                    margin-right: $wk-header-icon-margin-right;
                }

                //legacy icons
                @include wk-target-icons {
                    color: $wk-header-nav-icons-color;
                    font-size: wk-rem(22px);
                    margin-left: 0;
                    position: relative;
                    top: wk-rem(2px);
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            &.wk-green-icons {
                > li {
                    //new icons
                    [wk-icon] {
                        color: wk-primary-color(wkGreen);
                    }

                    //legacy icons
                    @include wk-target-icons {
                        color: wk-primary-color(wkGreen);
                    }
                }
            }

            &.wk-gray-icons {
                > li {
                    //new icons
                    [wk-icon] {
                        color: wk-primary-color(wkGray, tint1);
                    }

                    //legacy icons
                    @include wk-target-icons {
                        color: wk-primary-color(wkGray, tint1);
                    }

                    // scss-lint:disable NestingDepth
                    > a:hover {
                        //new icons
                        [wk-icon] {
                            color: wk-primary-color(wkGreen);
                            transition: color .3s;
                        }

                        //legacy icons
                        @include wk-target-icons {
                            color: wk-primary-color(wkGreen);
                            transition: color .3s;
                        }
                    }
                    // scss-lint:enable NestingDepth
                }
            }
        }

        .wk-nav {
            @include wk-hidden;
            background-color: $wk-header-nav-background;
            color: $wk-header-nav-color-mobile;
            float: left;
            width: 100%;

            @include breakpoint($wk-target-tablet-desktop) {
                @include wk-shown;
                background-color: transparent;
                float: right;
                margin-top: wk-rem(19px);
                width: auto;
            }

            //logic from page-content component
            @include breakpoint($wk-desktop-max-width + (2 * $wk-page-content-padding)) {
                padding: 0;
            }

            @include wk-target-icons {
                line-height: 0;
            }

            > li {
                &:last-child {
                    padding-right: 0;
                }

                > a {
                    padding: wk-rem(10px 15px);
                }

                > a,
                > a:hover {
                    color: $wk-header-nav-link-color;

                    @include breakpoint($wk-target-tablet-desktop) {
                        color: $wk-header-nav-color;
                    }
                }

                > a:hover {
                    color: $wk-header-nav-link-color-hover;
                }
            }

            &.open {
                @include wk-shown;

                background-color: rgba($wk-header-nav-open-background, $wk-menu-opacity);
                position: absolute;
                top: wk-rem(47px); //TODO a new grid system would only use multiples of 16 JIRA JUMPSTART-222
                z-index: $wk-header-nav-open-z-index;

                @include breakpoint($wk-target-tablet-desktop) {
                    background-color: transparent;
                    position: relative;
                    top: 0;
                }

                [wk-icon] {
                    color: $wk-header-nav-open-icons-color;
                }

                @include wk-target-icons {
                    color: $wk-header-nav-open-icons-color;
                }
            }
        }

        .wk-more-menu {
            @include wk-unstyle-list(true);

            float: right;
            font-family: map-get($wk-font-stacks, global-sans);

            @include breakpoint($wk-target-tablet-desktop) {
                @include wk-hidden;
            }

            a {
                color: $wk-header-nav-color;
                min-height: 47px; //TODO a new grid system would only use multiples of 16 JIRA JUMPSTART-222
                padding: wk-rem(12px) $wk-page-content-padding wk-rem(11px);
            }

            a:hover {
                color: $wk-header-nav-link-color-hover;
            }

            &.open {
                background-color: rgba($wk-header-nav-more-background, $wk-menu-opacity);

                @include wk-target-icons {
                    color: $wk-header-nav-open-icons-color;
                }

                a {
                    color: $wk-header-nav-open-color;
                }

                a:hover {
                    color: $wk-header-nav-link-color-hover;
                }
            }
        }
    }
}
