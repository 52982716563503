////
/// @group wk-css.components.modal
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.10.0
/// @access public
////

@import 'modal.variables';

@include wk-exports('modal') {

    %scss-deps-modal {
        components: wk-component-dependencies(layout);
        utils: wk-utils-dependencies(
            function wk-primary-color,
            variable wk-font-scale,
            variable wk-font-weight,
            variable wk-z-index
        );
    }

    .wk-modal {
        bottom: 0;
        display: none;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $wk-modal-z-index;

        &.wk-modal-opened {
            display: inline;
        }

        &.wk-modal-small {
            .wk-modal-content {
                @include wk-layout-centered-small;
            }
        }

        &.wk-modal-medium {
            .wk-modal-content {
                @include wk-layout-centered-medium;
            }
        }

        &.wk-modal-large {
            .wk-modal-content {
                @include wk-layout-centered-large;
            }
        }

        // Could be a good way to add the overlay when needed
        // without having to add an empty element
        &.wk-modal-with-overlay::before {
            background-color: $wk-modal-overlay-color;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: $wk-modal-overlay-z-index; // relative to .wk-modal
        }

        &.wk-modal-vertical-middle {
            .wk-modal-content {
                left: 50%;
                margin-top: 0;
                position: absolute;
                top: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
            }

            &.wk-modal-large-content {
                .wk-modal-content {
                    left: auto;
                    margin-top: 30px;
                    position: static;
                    top: auto;
                    transform: none;
                }
            }
        }

        .wk-modal-content {
            background: $wk-modal-content-background;
            box-shadow: $wk-modal-box-shadow;

            @include breakpoint($wk-target-tablet-desktop) {
                margin: 30px;
            }

            .wk-modal-close {
                color: $wk-modal-close-color;
                cursor: pointer;
                font-size: wk-rem(22px);
                position: absolute;
                right: 0;
                top: 6px;
                transition: all .1s ease-out;
                width: $wk-modal-close-width;
            }

            button.wk-button-icon.wk-modal-close {
                background-color: $wk-modal-close-background-color;
                padding: wk-rem(7px);
            }

            .wk-modal-close:hover {
                color: $wk-modal-close-hover;
            }

            .wk-modal-close:focus {
                color: $wk-modal-close-focus-color;
            }

            .wk-modal-header {
                background: $wk-modal-header-background;
                min-height: wk-rem(48px);
                padding-bottom: $wk-modal-header-padding-bottom;
                padding-left: $wk-modal-header-padding-left;
                padding-right: $wk-modal-header-padding-right;
                padding-top: $wk-modal-header-padding-top;
                position: relative;

                .wk-modal-title {
                    color: $wk-modal-title-color;
                    font-family: map-get($wk-font-stacks, global-sans);
                    font-size: map-get($wk-font-scale, base);
                    font-weight: map-get($wk-font-weight, regular);
                    margin: 0;
                }
            }

            .wk-modal-body {
                padding: $wk-modal-inner-padding;
                position: relative;
            }

            .wk-modal-footer {
                padding: 0 $wk-modal-inner-padding $wk-modal-inner-padding;
            }
        }
    }

    .wk-modal-body-hidden {
        overflow: hidden;
    }
}
