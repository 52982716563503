////
/// @group wk-css.components.buttons
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.3.0
/// @access public
////

@import 'buttons.variables';
@import 'buttons.mixins';
@import 'wk-components/animation/animation.mixins';
@import 'buttons.placeholders';

@include wk-exports('buttons') {

    %scss-deps-buttons {
        components: wk-component-dependencies(
            icons,
            forms,
            layout
        );
        utils: wk-utils-dependencies(
            mixin wk-spin,
            function wk-primary-color,
            variable wk-font-scale,
            variable wk-headings-font-stack,
            variable wk-target-tablet-desktop,
            variable wk-font-weight
        );
    }

    button,
    .wk-button,
    [wk-button] {
        @extend %wk-button;
    }

    a.wk-button,
    a[wk-button] {
        @extend %wk-button-link;
    }

    .wk-button-bar {
        @extend %wk-button-bar;
    }
}
